import React from 'react'
import classes from './DToneOperators.module.scss'

const OperatorCardPublic = ({label, image, altImage}) => {

  return (
    <div className={classes["rb-container"]}>
      <div className={classes["rb-imageWrapper"]}>
        <div className={classes["rb-image"]}>
         <img src={image} alt={label} onError={(e) => e.target.src = altImage} />
        </div>
      </div>
      {label && <p className={classes["rb-label"]}>{label}</p>}
    </div>
  )
}

export default OperatorCardPublic