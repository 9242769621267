// CMS file to show multiple field based on the type of service given as input
import React, { useState, useEffect } from 'react';
import {
  getCountriesPublicQuery,
  getServicesPublicQuery,
  setTransactionDetails,
  setTransferAmountDT,
} from '../../../../redux/services/actions';
import {
  mobileTopUpProductsPublic,
  operatorsByPhoneNumberPublic,
  setAvailableOperators,
  setCountryCallingCode,
  setCountryCode,
  setSelectedOperator,
  setCountryId,
  setRecipientPhoneNumber,
  setProducts,
  setSelectedProduct,
  setReqFieldInfo,
  setRequiredFields,
  clearMobileFlowState,
} from '../../../../redux/mobileTopUp/actions';
import DropDown from '../../../../remitbee/components/dropDown/DropDown';
import DropDownItem from '../../../../remitbee/components/dropDown/DropDownItem';
import Box from '@mui/material/Box';
import Flag from '../../../../remitbee/components/flag/Flag';
import RangedProduct from './RangedProduct';
import FixedProducts from './FixedProducts';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../remitbee/components/button/Button';
import { useRouter } from 'next/router';
import Typography from '../../../../remitbee/components/typography/Typography';
import { useTranslation } from 'next-i18next';
import { parseArrFromString } from '../../../../shared/utility';
import Splash from '../../../../remitbee/components/splash/Splash';
import Dialog from '../../../../remitbee/components/dialogs/Dialog';
import { useMediaQuery } from '@mui/material';
import PhoneNumberInput from '../../../../remitbee/components/input/phoneNumberInput/PhoneNumberInput';
import Spacing from '../../../../remitbee/styles/spacing/Spacing';
import Tabs from '../../../../remitbee/components/tab/Tab';
import customStyles from './DToneOperators.module.scss'
import ChangeCarrier from '../../../mobileTopUp-v2/ChangeCarrier';
import rbTheme from '../../../../remitbee/theme/Theme';
import { MOCK_DATA } from '../../reusableSections/new/ServiceSection/StorybookMock';
import theme from '../../../../remitbee/theme/Theme';

const DToneOperators = ({ services, storybookMock }) => {
  const router = useRouter();
  const { selected_country, selected_service } = services ? typeof services === 'string' ? parseArrFromString(services)[0] : services[0] : null;
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
  const dispatch = useDispatch();
  const {
    recipientPhoneNumber,
    countryCallingCode,
    availableOperators,
    countryCode,
    selectedProduct,
    productAmount,
    products,
    selectedOperator,
    reqFieldInfos,
  } = useSelector((state) => state.mobileTopUp);
  const [countries, setCountries] = useState([]);
  const { t } = useTranslation('landing');
  const [filteredCountryList, setFilteredCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [phoneNumberError, setPhoneNumberError] = useState(null);
  const [availableTabs, setAvailableTabs] = useState([]);
  const [promises, setPromises] = useState([null, null, null, null]); //top up fixed, top up ranged, data, plans;
  const [activeTab, setActiveTab] = useState(0);
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [warnedUser, setWarnedUser] = useState(false);
  const [showProviders, setShowProviders] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleProductSelection = (product) => {
    if (!product) return;
    const fieldsToSet = product?.transactionRequiredFields?.flatMap((each) => each?.requiredFields);
    if (fieldsToSet && fieldsToSet?.length > 0) {
      let fields = {};
      fieldsToSet.forEach((field) => {
        if (field?.dtoneFieldName.includes('MobileNumber'))
          fields[field?.dtoneFieldName] = `+${countryCallingCode}${recipientPhoneNumber}`;
        else fields[field.dtoneFieldName] = '';
      });
      dispatch(setReqFieldInfo(fields));
    }
    dispatch(setRequiredFields(product.transactionRequiredFields));
  };

  const tabsContent = [
    {
      label: 'Top-up',
      id: 101,
      content: (
        <Box mt={isMobile ? -1 : -2}>
          <RangedProduct
            selectedOperator={selectedOperator}
            onSelect={handleProductSelection}
            mobileTopUpProducts={mobileTopUpProductsPublic}
            subServiceId={101}
          />
        </Box>
      ),
    },
    {
      label: 'Data',
      id: 103,
      content: (
        <Box mt={isMobile ? -1 : -2}>
          <FixedProducts
            selectedOperator={selectedOperator}
            onSelect={handleProductSelection}
            mobileTopUpProducts={mobileTopUpProductsPublic}
            type="data"
            subServiceId={103}
          />
        </Box>
      ),
    },
    {
      label: 'Plans',
      id: 102,
      content: (
        <Box mt={isMobile ? -1 : -2}>
          <FixedProducts
            selectedOperator={selectedOperator}
            onSelect={handleProductSelection}
            mobileTopUpProducts={mobileTopUpProductsPublic}
            type="plans"
            subServiceId={102}
          />
        </Box>
      ),
    },
  ];

  // Load all countries and services
  useEffect(() => {
    setLoading(true);
    dispatch(clearMobileFlowState());
    getCountryData();
    setLoading(false);
  }, []);

  const getCountryData = async () => {
    try {
      if(!selected_service) return;
      if(storybookMock) {
        setCountries(MOCK_DATA.TopUp.countries);
        return;
      }
      const { success, data } = await getServicesPublicQuery();
      if (success && data) {
        const services = data.getServicesPublic;
        const filteredServices = Object.values(services).filter(
          (key) => key.name === selected_service
        );

        const countryList = await getCountriesPublicQuery(filteredServices[0].id);
        if (countryList?.success && countryList?.data?.getCountriesPublic?.countries)
          setCountries(countryList?.data?.getCountriesPublic?.countries);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Once countries are loaded, inputs can load selected country-currency pair from redux
  useEffect(() => {
    if (countries && countries.length) {
      if(!selected_country) return;
      setFilteredCountryList(countries);
      const selected = countries.find((c) => c.name === selected_country);
      if (!selected) return;
      dispatch(setCountryId(selected?.countryId));
      dispatch(setCountryCode(selected?.iso2));
      dispatch(setCountryCallingCode(selected?.callingCode));
      setSelectedCountry(selected);
    }
  }, [countries]);

  
    const loadOperators = async () => {
      if (!(recipientPhoneNumber?.length > 6)) return;
      if(storybookMock) {
        const selectedOperator = MOCK_DATA.TopUpProduct.operators.find((operator) => operator.identified) || MOCK_DATA.TopUp.operators[0];
        dispatch(setAvailableOperators(MOCK_DATA.TopUpProduct.operators));
        dispatch(setSelectedOperator(selectedOperator));
        return;
      }
      setLoadingBtn(true);
      if(storybookMock) {
        const selectedOperator = TopupData.operators[0];
        dispatch(setAvailableOperators(TopupData.operators));
        dispatch(setSelectedOperator(selectedOperator));
        setLoadingBtn(false);
        return;
      }
      const { success, data, errorMessage } = await operatorsByPhoneNumberPublic({
        callingCode: countryCallingCode,
        phoneNumber: recipientPhoneNumber,
      });
      if (!success) {
        setLoadingBtn(false);
        setPhoneNumberError(errorMessage || 'Phone number invalid');
        return;
      }

      setPhoneNumberError(null);
      const selectedOperator =
        data.getOperatorsByPhoneNumberPublic.find((operator) => operator.identified) ||
        data.getOperatorsByPhoneNumberPublic[0];
      dispatch(setAvailableOperators(data.getOperatorsByPhoneNumberPublic));
      dispatch(setSelectedOperator(selectedOperator));
      setLoadingBtn(false);
    };

    useEffect(() => {
      if (selectedProduct && selectedProduct.type && selectedProduct.type.includes("FIXED")) {
          dispatch(setTransferAmountDT(selectedProduct.retailAmount));
      }
  }, [selectedProduct]);

  useEffect(() => {
    if (!selectedOperator) return;
    dispatch(setTransactionDetails({ status:'INITIATED' }));
    const promisesTemp = promises;
    const tabs = [];
    selectedOperator?.id &&
      selectedOperator?.subServices?.length &&
      selectedOperator?.subServices?.forEach((subService, index, array) => {
        const tab = tabsContent.find((content) => content.id === subService.id);
        if (tab) {
          tabs.push({ ...tab, id: subService.id });
          switch (subService.id) {
            case 101:
              promisesTemp[0] = mobileTopUpProductsPublic({
                operatorId: parseInt(selectedOperator?.id),
                subServiceId: subService.id,
                type: 'RANGED_VALUE_RECHARGE',
              });
              promisesTemp[1] = mobileTopUpProductsPublic({
                operatorId: parseInt(selectedOperator?.id),
                subServiceId: subService.id,
                type: 'FIXED_VALUE_RECHARGE',
                pageOptions: { currentPage: 1, rowsPerPage: 10 },
              });
              break;
            case 102:
              promisesTemp[2] = mobileTopUpProductsPublic({
                operatorId: parseInt(selectedOperator?.id),
                subServiceId: subService.id,
                type: 'FIXED_VALUE_RECHARGE',
                pageOptions: { currentPage: 1, rowsPerPage: 10 },
              });
              break;
            case 103:
              promisesTemp[3] = mobileTopUpProductsPublic({
                operatorId: parseInt(selectedOperator?.id),
                subServiceId: subService.id,
                type: 'FIXED_VALUE_RECHARGE',
                pageOptions: { currentPage: 1, rowsPerPage: 10 },
              });
              break;
          }
        }
        if (index === array.length - 1) {
          setAvailableTabs(tabs.sort((a, b) => a.id - b.id));
          //clean products state
          dispatch(setProducts(null));
          loadProductsPromise(promisesTemp);
        }
      });
  }, [selectedOperator]);

  const loadProductsPromise = async (promises) => {
    if(storybookMock) {
      const products = TopupData.products;
      dispatch(setProducts({
        topUpRanged: [],
        topUpFixed: products,
        data: products,
        plans: products,
      }));
      return;
    }
    setPromises(promises);
    if(storybookMock) {
      const products = {
        topUpRanged: MOCK_DATA.TopUpProduct.products,
        topUpFixed: MOCK_DATA.TopUpProduct.products,
        data: MOCK_DATA.TopUpProduct.products,
        plans: MOCK_DATA.TopUpProduct.products
      };
      dispatch(setProducts(products));
      return;
    }
    const [resultTopUpRanged, resultTopUpFixed, resultPlans, resultData] = await Promise.all(
      promises
    );
    const products = {
      topUpRanged: resultTopUpRanged?.data?.getProductsPublic?.products,
      topUpFixed: resultTopUpFixed?.data?.getProductsPublic?.products,
      data: resultData?.data?.getProductsPublic?.products,
      plans: resultPlans?.data?.getProductsPublic?.products,
    };
    dispatch(setProducts(products));
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(null);
    const itemData = JSON.parse(e.currentTarget.getAttribute('data-item'));
    if (!itemData) return;
    setSelectedCountry(itemData);
    dispatch(setCountryId(itemData?.countryId));
    dispatch(setCountryCode(itemData?.iso2));
    dispatch(setCountryCallingCode(itemData?.callingCode));
  };

  const renderCountryList = (country) => {
    if (!country) return null;
    return (
      <DropDownItem
        key={country.countryId}
        value={country.countryId}
        data={country}
        onClick={handleCountryChange}
      >
        <Box display="flex" alignItems="center">
          <Flag code={country.iso2} />
          <Box ml={3}>{country.name}</Box>
        </Box>
      </DropDownItem>
    );
  };

  const handleCountrySearch = (typed, receiveSuggestions) => {
    if (typed && typed !== '') {
      const filteredList = countries?.filter((c) =>
        c?.name?.toUpperCase().includes(typed.toUpperCase())
      );
      filteredList.length > 0
        ? setFilteredCountryList(filteredList)
        : setFilteredCountryList(receiveSuggestions);
    } else setFilteredCountryList(countries);
  };

  const handleChangeTab = (tab) => {
    if (activeTab === tab) return;
    setPhoneNumberError(null);
    dispatch(setSelectedProduct(null));
    setActiveTab(tab);
  };
  const clearFieldsFromPhoneNumber = () => {
    dispatch(setSelectedOperator(null));
    dispatch(setAvailableOperators(null));
    dispatch(setSelectedProduct(null));
    dispatch(setProducts(null));
    setActiveTab(0);
    setSelectedCountry(null);
  };

  const propsToBePassed = {
    countryCode,
    recipientPhoneNumber,
    setRecipientPhoneNumber: (e) => {
      dispatch(setRecipientPhoneNumber(e));
    },
    countryCallingCode: selectedCountry?.callingCode,
    error: phoneNumberError,
    setError: setPhoneNumberError,
  };

  const handleContinueToPayment = () => {
    const transactionDetails = {
        destination_country: selectedProduct.country.name,
        service_type: "Mobile top-up",
        service_provider: selectedOperator.label || selectedOperator.name,
        recipient_details: {
            ...reqFieldInfos,
            recipientPhoneNumber,
            countryCallingCode
        },
        product: selectedProduct,
        final_transfer_amount: selectedProduct.retailAmount,
        product_value: `${productAmount} ${selectedProduct.destinationUnit}`,
        dt_flow: 'mobile'
    }

    dispatch(setTransactionDetails(transactionDetails))
    router.push('/mobile-top-up/payment');
}

  const onChangeCarrier = (operator) => {
    setActiveTab(0);
    setShowProviders(!showProviders);
    dispatch(setSelectedOperator(operator));
    dispatch(setSelectedProduct(null));
  };

  if(loading) return <Splash />;

  return (
    <Box
      justify="center"
      mt={isMobile ? 5 : 6}
      style={{ width: !isMobile ? '500px' : '100%', textAlign: 'start' }}
    >
      <DropDown
        id="country-selection"
        label={'Select country'}
        placeholder={'Select country'}
        list={
          countries.length > 0 && countries.length === filteredCountryList?.length
            ? countries.map((country) => renderCountryList(country))
            : filteredCountryList?.length
            ? filteredCountryList?.map((country) => renderCountryList(country))
            : filteredCountryList
        }
        onSearch={handleCountrySearch}
        selectedItem={selectedCountry && renderCountryList(selectedCountry)}
        openByDefault={false}
      />
      
      {selectedCountry && (
        <>
        <Spacing variant='tabToBody' />
        <PhoneNumberInput
          countryCallingCode={selectedCountry?.callingCode || '1'}
          recipientPhoneNumber={recipientPhoneNumber}
          defaultPhoneCode={selectedCountry?.countryId}
          error={phoneNumberError}
          placeholder='Enter recipient number'
          label='Phone number'
          codeList={[
            {
              countryCode: selectedCountry?.iso2,
              callingCode: selectedCountry?.callingCode,
              countryId: selectedCountry?.countryId,
            },
          ]}
          withOutDropdown={true}
          selectable={false}
          onPhoneNumberChange={(e) => {
            dispatch(setRecipientPhoneNumber(e));
          }}
        />
        </>
      )}
      {selectedOperator &&
        selectedOperator?.imageUrl &&
        selectedOperator?.name &&
        !phoneNumberError && (
          <>
          <Spacing variant='titleToDivider' />
          <Box display="flex" alignItems="center">
            <Box flexGrow={1} display={'flex'} rowGap={'8px'}>
              <Typography variant='body1' color={rbTheme.palette.primary.navy} style={{ paddingRight: '8px' }}>
                Mobile carrier:
              </Typography>
              <Box display="flex" columnGap={'8px'} alignItems="center">
                <Box display="flex">
                  <img
                    src={selectedOperator.imageUrl}
                    style={{ maxWidth: '30px', width: '100%' }}
                    onError={(e) => (e.target.src = '/services/topUpPlaceholder.svg')}
                  />
                </Box>
                <Box display="flex">
                  <Typography variant='body1' color={rbTheme.palette.primary.navy} weight='semibold'>
                    {selectedOperator.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Button className={customStyles['rb-text-button']} variant='text' onClick={() => setShowProviders(!showProviders)}>
                Edit
              </Button>
            </Box>
          </Box>
          </>
        )}
      {showProviders && (
        <ChangeCarrier
          flow={'landing'}
          t={t}
          onContinue={onChangeCarrier}
          selectedOperator={selectedOperator}
          operatorsAvailable={availableOperators}
          {...propsToBePassed}
        />
      )}

      <Spacing variant='contentToCta' />
      {availableTabs?.length > 0 && products && !phoneNumberError && !showProviders ? (
        <>
          <Tabs
            tabContent={tabsContent}
            tabHeaderClassName={customStyles['rb-custom-tab-header']}
            defaultActiveTab={activeTab}
            onChange={handleChangeTab}
            type='button'
            />
          <Spacing variant='contentToCta' />
          {!showProviders && <Button
            disabled={!selectedProduct}
            fullWidth
            onClick={() => {
              setWarnedUser(true);
            }}
          >
            Top Up Now
          </Button>}
        </>
      ) : (
        showProviders ? null : <Button
          disabled={!(recipientPhoneNumber?.length > 6)}
          fullWidth
          loading={loadingBtn}
          onClick={loadOperators}
        >
          Check plans
        </Button>
      )}
      {warnedUser && (
        <Dialog
          header={t('landing:warn_user.title')}
          text={t('landing:warn_user.text1')}
          content={<Typography>{t('landing:warn_user.text2')}</Typography>}
          primaryActionText="Continue to payment"
          primaryAction={handleContinueToPayment}
          variant="warning"
          onClose={() => setWarnedUser(false)}
          open={true}
        />
      )}
    </Box>
  );
};
export default DToneOperators;
